import {UtilService} from "../../../shared/util.service";
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Hotel } from 'app/shared/model';
import { Subscription } from "rxjs";
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { CustomersState } from 'app/states/customers/customers.state';

export class FilterValue{
  selected : any = null;
  values: string [] = [];
}

export class Filters{
  all = new FilterValue();
  brands  = new FilterValue();
  regions = new FilterValue();
}

const STORAGE = 'chain-zone-selected-';

@Component({
  selector: 'widget-filter-chain-zone',
  templateUrl: './chain-zone.component.html',
  styleUrls: ['./chain-zone.component.scss']
})
export class ChainZoneComponent implements OnInit, OnDestroy {

  @Select(CustomersState.relativeCurrentChain) customersReady$: Observable<any[]>;

  @Input()
  productId;

  chain:Hotel[] = [];

  @Input()
  disabled = false;

  filters: any = new Filters();

  @Output()
  chainChanged: EventEmitter<Hotel[]> = new EventEmitter();

  customerSubscribe: Subscription;
  formatedChain: any[]

  constructor(
    private utilService : UtilService,
    private store: Store
  ) {
    this.customerSubscribe = this.utilService.isCustomerChanged().subscribe(response=>{
      this.init();
    })
  }

  ngOnDestroy(){
    this.customerSubscribe.unsubscribe();
  }

  ngOnInit() {
    this.init();
  }

  init(){
    let chain = this.store.selectSnapshot(CustomersState.relativeCurrentChain);
    chain = chain.map(customer => {
      return {
        ...customer,
        chain_brand: customer.chain_property.brand,
        chain_region: customer.chain_property.region
      }
    });
    this.formatedChain = chain;

    const brands = {}
    const regions = {};

    chain.forEach(customer=>{
      brands[customer.chain_brand]   = customer.chain_brand;
      regions[customer.chain_region] = customer.chain_region;
    })

    this.filters.all.values  = ['all'];
    this.filters.brands.values   = Object.values(brands);
    this.filters.regions.values  = Object.values(regions);

    ['regions','brands'].forEach(key=>{
      this.checkSizes(key)
    });

    this.checkSelected();
    this.chainResult();

  }

  checkSizes(key){
    if(!this.filters[key].values.includes('') || this.filters[key].values.length > 1){
      this.filters.all.values    = this.filters.all.values.concat([key])
      this.filters[key].selected = this.filters[key].values[0];
    }
  }

  checkSelected(){
    this.getOptionSelected('regions');
    this.getOptionSelected('brands');
    this.getOptionSelected('all');
  }


  getOptionSelected(key){
    let currentCustomer = this.utilService.getCurrentHotel();    
    currentCustomer = this.formatedChain.find( hotel => hotel?.id === currentCustomer?.id );
    const storageKey      = STORAGE+currentCustomer.id+key;
    let value             = localStorage.getItem(storageKey);
    let keyData;
    let selected;

    switch (key) {
      case 'regions':
          keyData = 'chain_region';
        break;
      case 'brands':
          keyData = 'chain_brand';
          break;
    }


    if(key==='all'){
      if(this.filters.all.values.includes(value)){
        selected = value;
      }else{
        if(this.chain.length>20 && !this.utilService.customerIsRetail){
          ['regions','brands'].forEach(type=>{
            if(this.filters.all.values.includes(type)) selected = type;
          });
          if(!['regions','brands'].includes(selected)) selected ='all';
        }else{
          selected = 'all';
        }
      }
    }else{
      selected = this.filters[key].values.includes(value) ? value : currentCustomer[keyData];
    }

    this.filters[key].selected = selected;
  }


  chainResult(){
    let customers:Hotel[] = [];
    let chain = this.store.selectSnapshot(CustomersState.relativeCurrentChain);

    chain = chain.map(customer => {
      return {
        ...customer,
        chain_brand: customer.chain_property.brand,
        chain_region: customer.chain_property.region
      }
    });

    ['all','regions','brands'].forEach(key=>{
      const storageKey = STORAGE+key;
      localStorage.setItem(storageKey, this.filters[key].selected);
    })

    switch (this.filters.all.selected) {
      case 'all':
        customers = chain;
        break;
      case 'brands':
        customers = chain.filter(x=>x.chain_brand === this.filters.brands.selected);
        break;
      case 'regions':
        customers = chain.filter(x=>x.chain_region === this.filters.regions.selected);
        break;
    }
    this.chainChanged.emit(customers);
  }

}
